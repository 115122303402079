.search-item-container {
    background-color: transparent;
    height: fit-content;
    color: rgb(230, 223, 223);
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius:  150px ;
    padding: 0 1rem 3px;
    font-size: 17px;
}

.search-item-link {
    color: inherit;
    text-decoration: inherit;    
    z-index: 2;
}

.selected-search {
    background-color: var(--selectedElement);
}

.search-item-container:hover {
    background-color: var(--selectedElement);
}

