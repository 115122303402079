.openButton {
    position:fixed;
    bottom: 5vh;
    right: 10vw;
    background-color: var(--redBoard);
}

.openIcon {
    color: white;
}

.modal-window {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10%;
    overflow-y: scroll;
    overflow-x: hidden;
    line-height: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-window::-webkit-scrollbar {
    -webkit-appearance: none;
}

.modal-window::-webkit-scrollbar:vertical {
    width: 10px;
}

.modal-window::-webkit-scrollbar-button {
    display: none;
}

.modal-window::-webkit-scrollbar:horizontal {
    height: 10px;
}

.modal-window::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 1em;
    border: none;
}

.modal-window::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1em;
    border: none;
}

.modal-window::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 1em;
    border: none;
}

.modal-window::-webkit-scrollbar-track {
    border-radius: 1em;
}