*{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #EEE;
}

.icon{
  color: rgb(245, 69, 69);
}

.faic{
  display: flex;
  align-items: center;
  justify-content: center;
}

:root{
  --secondaryColor : #303134;
  --selectedElement : #3C4043;
  --sideBar : #232331;
  --principalBlue : #15202B;
  --blueWhite: #F0F4F6;
  --redBoard: #a00;
  --hoverRedBoard: #800000;
  --calificado: #d41616;
  --adminColor: #7289da;
  --widthDragAndDrop: 220px;
}