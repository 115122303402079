.principal-menu-bar {
    background-color: var(--principalBlue);
    height: calc(100vh - 54px);
    float: left;
    box-sizing: border-box;
}

.principal-menu-bar-non-clicked {
    width: 25%;
    overflow: scroll;
    overflow-x: hidden;
    padding: 0 20px 85px 20px;
    box-sizing: border-box;
    transition-duration: 300ms;
}

.hide-principal-menu-bar {
    width: 0;
    overflow: hidden;
    box-sizing: border-box;
    transition-duration: 300ms;
    position: absolute;
    left: -150%;
}

body {
    overflow: hidden;
    background-color: var(--principalBlue);
}

.principal-menu-bar::-webkit-scrollbar, .files-section::-webkit-scrollbar {
    -webkit-appearance: none;
}

.principal-menu-bar::-webkit-scrollbar:vertical, .files-section::-webkit-scrollbar:vertical {
    width:10px;
}

.principal-menu-bar::-webkit-scrollbar-button:increment, .principal-menu-bar::-webkit-scrollbar-button {
    display: none;
}

.principal-menu-bar::-webkit-scrollbar:horizontal, .files-section::-webkit-scrollbar:horizontal {
    height: 10px;
}

.principal-menu-bar::-webkit-scrollbar-thumb, .files-section::-webkit-scrollbar-thumb {
    background-color: rgb(80, 93, 106);
    border-radius: 20px;
    border: 1px solid rgb(80, 93, 106);
}

.principal-menu-bar::-webkit-scrollbar-thumb:hover, .files-section::-webkit-scrollbar-thumb:hover {
    background-color: rgb(60, 73, 86);
    border-radius: 20px;
    border: 1px solid rgb(60, 73, 86);
}

.principal-menu-bar::-webkit-scrollbar-track, .files-section::-webkit-scrollbar-track {
    border-radius: 10px;
}

.general {
    background-color: #EEE;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    transition-duration: 300ms;
    box-sizing: border-box;
}

.files-section {
    padding: 0 0 85px 0;
    box-sizing: border-box;
    height: calc(100vh - 54px);
    float: left;
    overflow: scroll;
    overflow-x: hidden;
    background-color: #EEE;
}

.files-section-non-clicked {
    width: 75%;
    float: right;
    transition: width 150ms linear;
}

.files-section-clicked {
    width: 100%;
    float: right;
    transition: width 300ms linear;
    box-sizing: border-box;
}

.for-each-programme {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;

    /*add flexbox style*/
    display: flex;
    flex-direction : row;
    justify-content : left;
    flex-flow : wrap;
}

.title-programme {
    color: #FFF;
    background-color: var(--redBoard);
    border-radius: 0 0 150px 0;
    box-sizing: border-box;
    padding: 8px 60px 10px 20px;
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 30px;
    display: block;
    align-items: center;
    position: sticky;
    top: -1px;
    z-index: 2;
    height: 56px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; 
}

.hamburger-menu {
    color: #FFF;
    font-size: 32px;
    margin-right: 15px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 700ms;
    top: 3px;
    position: relative;
}

.hamburger-menu-clicked {
    top: 5px;
}

.hamburger-menu:active {
    transform: rotate(-270deg);
}


@media screen and (max-width: 600px) {
    .title-programme {
        display: block;
    }

    .principal-menu-bar {
        width: 100%;
        height: 100vh;
        z-index: 4;
        position: absolute;
        float: right;
        right: 0;
        top: 0;
        padding-left: 85px;
        transition-duration: 300ms;
        box-sizing: border-box;
    }

    .general {
        display: flex;
        flex-direction: column;
        transition-duration: 300ms;
    }

    .files-section {
        width: 100%;
        transition-duration: 300ms;
    }

    .hamburger-menu {
        cursor: pointer;
        font-size: 28px;
        margin-right: 5px;
    }

    .hamburger-menu-clicked {
        cursor: pointer;
        font-size: 40px;
        position: absolute;
        right: 5px;
        top: 25px;
        transition-duration: 300ms;
    }

    .red-bar {
        z-index: 5;
        position: absolute;
        transition-duration: 300ms;
        width: fit-content;
        height: 100%;
        padding: 40px 30px;
        align-content: center;
        align-self: center;
        align-items: center;
        font-size: 0;
        top: 0;
        color: transparent;
    }
}

@media screen and (max-width: 420px) {
    .hamburger-menu-clicked {
        font-size: 25px;
    }

    .red-bar {
        padding: 20px 40px 20px 0;
    }

    .principal-menu-bar {
        padding-left: 65px;
    }
}

