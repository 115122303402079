.dd-wrapper .dd-header {
    cursor: pointer;
    width: 100%;
    align-content: center;
    align-items: center;
    align-self: center;
    color: #fff;
    padding-bottom: 10px;
}

.dd-wrapper {
    display: flex;
    min-height: 16px;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0 0;
    list-style-type: none;
    font-size: 20px;
    text-align: left;
    cursor: pointer;
    font-weight: bold;
}

.chevron {
    font-size: 12px;
    color: #ffffff;
}

.options {
    padding: 8px 12px 8px 12px;
    margin: 3px;
    border-radius: 15px;
    border: 0;
    background-color: #c4c4c4;
    cursor: pointer;
    color: #000000;
    font-weight: bold;
}

.hover-options:hover {
    background-color: #9a9a9a;
    transition-duration: 300ms;
    color: #000000;
}

.selected {
    color: #fff;
    background-color: var(--redBoard);
}

.no-click {
    cursor: default;
    padding-top: 10px;
}

