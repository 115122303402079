.calificado {
  color: var(--calificado);
  font-weight: 700;
  border-top:  2px var(--calificado) solid;
  border-bottom:  2px var(--calificado) solid;
  font-size: 1rem;
  line-height: 1.3rem;
  letter-spacing: -1px;
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  left: 6%;
  top: 12%;
  transform: rotate(-22deg);
}

.modal {
  position: absolute;
  width: 80vw;
  height: 90vh;
  box-sizing: border-box;
  background-color: #fff;
  border: none;
  padding: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-container {
  text-align: center;
  width: 25%;
  padding: 8px;
  height: 200px;
  box-sizing: border-box;
  background-color: transparent;
}

@media screen and (max-width: 1000px) {
  .card-container {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .card-container {
    width: 100%;
  }
}

.pdf-container {
  height: 100%;
}

.files-programme {
  background-image: url("../assets/principal-logo-blue.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50%;
  border-radius: 50px 5px;
  background-color: rgb(64, 75, 87);
  color: rgb(255, 255, 255);
  height: 100%;
  padding: 10px 20px 30px;
  align-items: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.files-programme:hover {
  background-color: rgb(80, 93, 106);
  transition-duration: 300ms;
}

.files-programme:active {
  background-color: rgb(63, 74, 85);
}

@media only screen and (max-width: 320px) {
  .file-by-teacher-like{
    flex-direction: column;
  }
}

.delete {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  right: 10px;
  cursor: pointer;
}

.delete-component {
  color: #fff;
  padding: 11px 0;
}

.delete-component:hover {
  color: var(--redBoard);
}

.delete-component-admin-user {
  color: var(--adminColor);
}

.delete-component-admin-user:hover {
  color: #000;
}

.delete-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  line-height: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.delete-modal {
  position: relative;
  display: flex;
  padding: 10px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: whitesmoke;
  box-shadow: 10px -10px var(--principalBlue), 20px -20px whitesmoke;
}

.delete-modal > h1 {
  margin-top: 40px;
  text-align: center;
}

.delete-modal > span {
  margin: 0 30px 30px;
}

.delete-modal > div {
  align-self: flex-end;
  margin-right: 3%;
}

.delete-modal > div > button {
  margin: 5px 5px;
  padding: 8px 10px;
  font-family: inherit;
  font-weight: bold;
  background-color: inherit;
  cursor: pointer;
}

.delete-modal-delete {
  margin: 10px;
}

.edit {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  right: 32px;
  cursor: pointer;
}

.edit-component {
  color: #fff;
}

.edit-component:hover {
  color: #48a14d;
}

ul {
  list-style: none;
  padding: 0;
}

.file-by-type {
  font-weight: bold;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 50px;
  float: left;
  width: 100%;
}

.file-by-description {
  color: #ddd;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 90px;
  float: right;
  width: 100%;
}

.file-by-teacher {
  color: #ccc;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: lighter;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  align-self: center;
  align-items: center;
  position: absolute;
  bottom: 8px;
  left: 0;
  height: 17px;
  padding: 0 18%;
}

.like {
  padding: 0.2rem;
  text-decoration: underline;
  border-radius: 100px;
  font-weight: 900;
  position: absolute;
  left: 5px;
  bottom: 5px;
}

.like:hover {
  background-image: url("../assets/min-logo-likes.png");
  transition-duration: 300ms;
  color: black;
}

.load-spinner {
  color: var(--principalBlue);
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  font-size: 40px;
}
