.div-general {
    background-color: var(--principalBlue);
    width: 100%;
    /* mobile viewport bug fix */
    height: -webkit-fill-available;
    /* height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;*/
    display: flex;
}

.div-left {
    background-image: url("../assets/principal-logo-gray.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 103%;
    color: #DDD;
    font-size: 0.8rem;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    flex: 1;
    padding: 1.5em 1em;
    transition: 300ms;
    height: 100%;
    width: 50%;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.text-div-left {
    width: 85%;
}

.div-right {
    background-color: var(--blueWhite);
    color: #555;
    flex: 1;
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.link-privacy {
    margin-top: 1em;
}

.container-input-logo {
    width: 90%;    
    display: flex;
    flex-direction: column;
}

.google-out {    
    bottom: 5vh;
}

.signOut-container{
    position: fixed;
    bottom: 1vh;
}

.signIn-container{
    display: flex;
    justify-content: center;
}

.slogan-img {
    width: 300px;
    align-self: center;     
    margin-bottom: 1rem;
    transition: 300ms;
}

.legal {
    align-content: center;
    align-items: center;
    alignment: center;
    font-size: 9px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    max-width: 50%;
    min-width: 30%;
}

.politics {
    text-decoration: blink;
    color: #000;
    font-weight: bold;
}

.google-sign {
    color: rgb(0, 0, 0);
    height: 48px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 3px 5px 2px;
}

.non-unal-msg {
    color: #f30000;
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    margin-block-start: 0.3em;
    margin-block-end: 0.5em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    text-align: center;        
}


@media only screen and (max-width: 800px) {
    .div-left {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: fit-content;
        z-index: 1;
        background-color: var(--principalBlue);
        box-sizing: border-box;
        padding-top: 1em;
    }

    .div-right {
        width: 100%;
    }

    .signOut-container{
        left: 5vh;
    }

    .slogan-img {
        margin-bottom: 0.3rem;
    }
}

@media only screen and (max-width: 350px) {
    .slogan-img {
        width: 200px;
    }
}



