.main-navbar{
    background-color: var(--principalBlue);
    width: 100%;
    padding: 0 2px 4px;
    position: relative;
    box-sizing: border-box;
    height: 52px;
    top: 0;
    display: flex;
    justify-content: space-between;
}

.logo-image{
    width: 72%;
    padding-top: 14%;
}

.logo-container-nav {
    width: 54px;
    float: left;
    height: 10%;
    padding: 0 20px 0 10px;
    z-index: 3;    
}

.searcher-container-nav {
    width: 60%;    
    z-index: 3;
}

.log-out-container-nav {
    width: 54px;    
    height: 100%;
    padding: 0 5px 0 10px;
    z-index: 3;    
}

.sign-out {
    background: transparent;
    color: rgb(255, 255, 255);
    border-radius: 30px;
    border: 0;
    padding: 0 5px;    
    height: 100%;
    cursor: pointer;
    font-size: 21px;    
}

@media only screen and (max-width: 600px){    
    .searcher-container-nav {
        width: 90%;
        left: 0;
    }

    .log-out-container-nav {
        width: 10%;
    }

    .log-out-container-nav {
        padding: 0 0 0 10px;
    }
}
