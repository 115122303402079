.pdf-circle{
    height: 32px;
    padding: 0.1rem 0.2rem;
    border-radius: 5rem;
    border: 1px solid var(--redBoard);
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--redBoard);
    color: #FFF;
    font-weight: bold;
}

.uploaded-container{
    display: flex;
    box-sizing: border-box;
    max-width: var(--widthDragAndDrop);
    height: 3em;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    background-color: white;
    border-radius: 1rem;
    padding: 0 10px;
    -webkit-box-shadow: 0 0 10px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 10px -5px rgba(0,0,0,0.75);
    box-shadow: 0 0 10px -5px rgba(0,0,0,0.75);
}

.text-max-pdf {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 18px;
}

.close-icon{
    color: gray;
    cursor: pointer;
}